/*Custom JS START*/
(function($) {
	document.querySelector('video').playbackRate = .65;

	$(document).ready(function() {
		$('<span class="menu-arrow"><i class="fa-solid fa-angle-down"></i></span>').insertAfter('.menu-item-has-children > a');
		$('#nav-icon1').click(function() {
            $(this).toggleClass('open');
            $('.main-menu').slideToggle();
        });

        $('.hero-txt-right').clone().removeClass('align-items-end justify-content-end').addClass('align-items-center justify-content-center').appendTo('.home .main-section');
		$('.menu-arrow').click(function() {
			$(this).parent('.menu-item-has-children ').find('.sub-menu').slideToggle();
			$(this).parent('.menu-item-has-children ').siblings('.menu-item-has-children ').find('.sub-menu').slideUp();
		});


        var li_lentgh = $('.time-line-previous ul li').length / 2;
		$('.time-line-previous ul li:nth-child(' + li_lentgh + ') .time-line-item').css('border-bottom' , 'none');

		var btnTxt = $( '.time-line-wrap .btn').html();
		$('.time-line-wrap .btn').click(function() {
			$( '.time-line-wrap .btn').html(btnTxt);
			$(this).toggleClass('show');
			$( '.time-line-wrap .btn.show').html('Collapse Previous');
			$('.time-line-previous').slideToggle();
		});

		/*Custom tab with random adding class*/
		$( ".inner-links li" ).each(function(i) {
			var listNo = "list-" + i;
			$(this).attr( 'title' , listNo);
		});
		$( ".inner_tabs > div" ).each(function(i) {
			var listid = "list-" + i;
			$(this).attr( 'id' , listid );
		});

		$( ".inner-links2 li" ).each(function(i) {
			var listNo2 = "list2-" + i;
			$(this).attr( 'title' , listNo2);
		});
		$( ".inner_tabs2 > div" ).each(function(i) {
			var listid2 = "list2-" + i;
			$(this).attr( 'id' , listid2 );
		});
		$( ".inner-links li , .inner-links2 li" ).click(function() {
			$(this).siblings().removeClass('active');
			$(this).addClass('active');
			var a = $(this).attr('title');
			$('#' + a).siblings().hide();
			$('#' + a).fadeIn();
		});
		/*END :: Custom tab with random adding class*/


		$('.testimonial-wrap').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			prevArrow: '<button class="slick-prev"><i class="fa-solid fa-angle-left"></i></button>',
			nextArrow: '<button class="slick-next"><i class="fa-solid fa-angle-right"></i></button>',
			  responsive: [
			    {
			      breakpoint: 1200,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
			      }
			    },
			    {
			      breakpoint: 992,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
			      }
			    },
			    {
			      breakpoint: 640,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
			      }
			    }
			    // You can unslick at a given breakpoint now by adding:
			    // settings: "unslick"
			    // instead of a settings object
			  ]
		});
	});
	
	
	
})(jQuery);
/*Custom JS END*/
